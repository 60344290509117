<template>
  <b-modal
    id="add-invoice-modal"
    ref="add-invoice-modal"
    hide-header
    hide-footer
    centered
  >
    <div class="px-3">
      <!-- loading -->
      <div
        v-if="isLoading"
        class="text-center p-2"
      >
        <b-spinner
          style="width: 5rem; height: 5rem;"
          class="mr-1"
          variant="primary" 
        />
        <h2 class="my-2">
          กำลังสรุปยอดบิล...
        </h2>
      </div>

      <!-- success -->
      <div
        v-else-if="isSuccess"
        class="text-center p-2"
      >
        <feather-icon
          size="64"
          icon="CheckCircleIcon"
          class="text-success"
        />
        <h2 class="my-2">
          สรุปยอดบิลเรียบร้อย
        </h2>
        <h6 class="my-2">
          ระบบสรุปยอดบิลเรียบร้อยแล้ว สามารถตรวจสอบได้ที่เมนู <span class="text-primary"> “ยอดที่สรุปแล้ว”</span>
          หรือคลิกตรวจสอบด้านล่าง
        </h6>

        <div class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click.prevent="hideModal"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary"
            class="mr-1"
            @click.prevent="$emit('change-menu', 'invoices')"
          >
            ดูยอดที่สรุปแล้ว
          </b-button>
        </div>
      </div>

      <div
        v-else
        class="text-center p-2"
      >
        <feather-icon
          size="64"
          icon="AlertCircleIcon"
          class="text-warning"
        />
        <h2 class="my-2">
          ยืนยันการสรุปยอดบิล
        </h2>
        <h6 class="my-2">
          ระบบจะตัดยอดปัจจุบัน และแจ้งยอดที่ต้องชำระไปยังระบบของตัวแทน
          และจะเริ่มนับรอบใหม่ตั้งแต่วันนี้เป็นต้นไป
        </h6>
        <div class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click.prevent="hideModal"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary"
            @click.prevent="onClickConfirm"
          >
            ยืนยัน
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddInvoiceModal',
  props: {
    bill: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.payment.isAddingInvoice,
      isSuccess: (state) => state.payment.isAddingInvoiceSuccess,
    }),
  },
  methods: {
    ...mapActions(['addInvoice']),
    hideModal() {
      this.$bvModal.hide('add-invoice-modal')
    },
    onClickConfirm() {
      if(this.bill){
        this.addInvoice(this.bill)
      }
    },
  },
  
}
</script>
